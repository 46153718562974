/**
 * Reset some basic elements
 */
body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
    margin: 0;
    padding: 0;
}

/**
 * Basic styling
 */
body {
    font-family: $base-font-family;
    font-size: $base-font-size;
    line-height: $base-line-height;
    font-weight: 400;
    color: $text-color;
    background-color: $background-color;
}

html, body {
    font-size: 16px;
    height: 100%;
}

/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm {
    margin-bottom: $spacing-unit / 2;
}

/**
 * Images
 */
img {
    max-width: 100%;
    vertical-align: middle;
}

/**
 * Figures
 */
figure > img {
    display: block;
}

figcaption {
    font-size: $small-font-size;
}

/**
 * Lists
 */
ul, ol {
    margin-left: $spacing-unit;
}

li {
    > ul,
    > ol {
         margin-bottom: 0;
    }
}

/**
 * Headings
 */
h1, h2, h3, h4, h5, h6 {
    font-weight: 400;
    margin-bottom: 2rem;
    color: #555;
}

h2 {
    font-size: 2.125rem;
}

/**
 * Links
 */
a {
    code {
        color: #0277bd;
    }

    color: #0277bd;
    text-decoration: none;

    &:hover {
        color:  #00AFE4;
        text-decoration: underline;

        code {
            color:  #00AFE4;
            text-decoration: underline;
        }
    }
}

/**
 * Blockquotes
 */
blockquote {
    color: $grey-color;
    border-left: 4px solid $grey-color-light;
    padding-left: $spacing-unit / 2;
    font-size: 18px;
    letter-spacing: -1px;
    font-style: italic;

    > :last-child {
        margin-bottom: 0;
    }
}

/**
 * Code formatting
 */
code,
pre code {
    font-family: 'Source Code Pro', monospace;
    font-size: 1rem;
    color: #555555;
    background-color: #f5f5f5;
    border-radius: 2px;
    padding: 2px 4px;
}

pre code {
    padding: 0;
    white-space: pre;
}

kbd {
    color: #fff;
    font-family: 'Source Sans Pro', monospace;
    background-color: #757575;
    font-size: 1rem;
    border-radius: 2px;
    padding: 2px 4px;
}

/**
 * Icons
 */
.icon {

    > svg {
        display: inline-block;
        width: 16px;
        height: 16px;
        vertical-align: middle;

        path {
            fill: $grey-color;
        }
    }
}

ol li img {
    margin: 8px 0;
}

pre.highlight {
    background: #f5f5f5;
}

pre {
    padding: 9px 14px;
    position: relative;
}

code.language-copypaste {
    white-space: pre-wrap;
    display: inline-block;
    padding-right: 64px;
}

button.btn.btn--clipboard {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    display: block;
    color: #767676;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #e1e1e8;
    border-radius: 0 4px 0 4px;
    border-top: none;
    border-right: none;
    min-width: 0;
}

button.btn.btn--clipboard:hover {
    background: #014358;
    color: #ffffff;
}

.search-input {
    border-radius: 4px;
    border: 1px solid $gray;
    padding-left: 8px;
    padding-right: 8px;
    width: 240px;
}
