/*
 * Button base rules
 * We use the BEM naming convention (See https://en.bem.info/method/naming-convention/)
 * Base font size is 16px so 1rem == 16px
 */
.button,
.btn,
button {
    display: inline-block;
    position: relative;
    cursor: pointer !important;
    overflow: hidden;
    vertical-align: middle;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    border-radius: 2px;
    /*
     * Since we want to achieve 32px height, with 16px as base font-size, and excluding 2px from
     * the top and bottom borders, that gives us (30 - 2)/16 = 1.875,
     * which translates to 30px height, which when adding the 2px from top and bottom borders back
     * gives a final 32px height */
    line-height: 1.875rem; /* 30px */
    min-width: 5.5rem; /* 88px */
    color: #78909C;
    font-size: .875rem; /* 14px */
    font-weight: 400;
    font-family: 'Source Sans Pro', 'Helvetica','Arial', sans-serif;
    text-decoration: none;
    text-align: center;
    letter-spacing: 0;
    margin: 0;
    padding: 0 1rem; /* 0 16px */
    background-position: 0 0;
    background-color: transparent;
    background-image: none;
    white-space: nowrap;
    box-shadow: none;
    text-shadow: none;
}

.button:focus,
.btn:focus,
button:focus {
    color: #78909C;
}

.btn:active,
button:active {
    outline: none;
}

.btn.btn--2x,
button.btn--2x {
    font-size: 1.25rem; /* 20px */
    min-width: 9.375rem; /* 150px */
    line-height: 2.875rem; /* 46px for a 48px total with borders */
    padding: 0 2rem; /* 0 32px */
    font-weight: 500;
}

/*
 * Green button
 */
.btn.btn--cta,
button.btn--cta {
    color: #fff;
    background-color: #44B78B;
    border-color: #40AD84;
}

.btn.btn--cta:focus,
button.btn--cta:focus {
    color: #fff;
}

.btn.btn--cta:hover,
button.btn--cta:hover {
    color: #fff;
    background-color: #44B78B;
    border-color: #36916E;
}

.btn.btn--cta:active,
button.btn--cta:active {
    color: #fff;
    background-color: #3A9E78;
}

/*
 * Blue button
 */
.btn.btn--primary,
button.btn--primary {
    color: #fff;
    background-color: #026282;
    border-color: #025975;
}

.btn.btn--primary:focus,
button.btn--primary:focus {
    color: #fff;
}

.btn.btn--primary:hover,
button.btn--primary:hover {
    color: #fff;
    background-color: #026282;
    border-color: #023A4C;
}

.btn.btn--primary:active,
button.btn--primary:active {
    color: #fff;
    background-color: #024359;
}