@charset "utf-8";

@import 'colors';

// Our variables
$base-font-family: 'Source Sans Pro', sans-serif;
$base-font-size:   16px;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5rem;

$spacing-unit:     30px;

$text-color:       #555;
$background-color: #f5f5f5;
$brand-color:      #2a7ae2;

$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);

// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "base",
        "layout",
        "syntax-highlighting",
        "helpers",
        "buttons",
        "header",
        "footer"
;

