/**
 * Branding colors
 */

$brand-blue: #014358 !default;

$interface-blue: #026282 !default;
$interface-orange: #E09C1E !default;
$interface-green: #4FB34F !default;
$interface-chrome: #F0F0EC !default;
$interface-white: #F5F5F5 !default;

$severity-warning-dark: #f57c00 !default;
$severity-warning-light: #fff3e0 !default;
$severity-info-dark: #0277bd !default;
$severity-info-light: #e1f5fe !default;
$severity-danger-dark: #d32f2f !default;
$severity-danger-light: #ffebee !default;
$severity-success-dark: #00c853 !default;
$severity-success-light: #e0f2f1 !default;

$gray: #e5e5e5;
