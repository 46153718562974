main {
    padding-top: 0;
    padding-bottom: 0;
    background-color: $background-color;
}

main > section {
    position: relative;
    padding: 2rem 0;
    background-color: #F5F5F5;
}

/** Part of footer hack **/
.site-wrapper {
    position: relative;
    min-height: 100%;
}
/*************************/

.topics-list,
.collection-page-list {
    margin-bottom: 3.5rem;
    font-size: 1.25rem;
    line-height: 2.125rem;
}

/**
* Page content
*/
.page-content {
    padding: $spacing-unit 0;
}

.page-heading {
    font-size: 20px;
}

.post-list {
    margin-left: 0;
    list-style: none;

    > li {
        margin-bottom: $spacing-unit;
    }
}

.post-meta {
    font-size: $small-font-size;
    color: $grey-color;
}

.post-link {
    display: block;
    font-size: 24px;
}

/**
* Posts
*/
.post-header {
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #e5e5e5;
}

.post-title {
    font-size: 2.125rem;
    letter-spacing: -1px;
    line-height: 1;
    margin: 0;
}

.post-content {

    > * {
        margin: 2rem 0;
    }

    p {
        line-height: 1.75rem;
    }

    p:last-child {
        margin-bottom: 0;
    }

    ul, ol {
        margin-left: 30px;
    }

    h2 {
        font-size: 32px;
    }

    h3 {
        font-size: 26px;
    }

    h4 {
        font-size: 20px;
    }
}

.flash-message {
    margin: 1em 0 0px;
    padding: 15px 20px;
    border-radius: 5;
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {

    main {
        /** Part of footer hack **/
        padding-bottom: 128px; /* Height of the footer element */
    }

    main > *:first-child {
        /** 8rem , 4 for header and 4 by default **/
        padding-top: 10rem;
    }

    main > section {
        padding: 6rem 0;
    }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {

}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}

.center {
    text-align: center;
}

.three-fourths-size {
    width: 75%;
}

.card {
    background: #fff;
    padding: 32px 128px 32px 32px;
    border-radius: 4px;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14),0 1px 5px 0 rgba(0,0,0,0.12),0 3px 1px -2px rgba(0,0,0,0.2) !important;
}

.page-top-navigation {
    .page-top-navigation-content {
        display: flex;
        margin-top: 8px;
        margin-bottom: 8px;
        padding: 0;
        justify-content: space-between;

        .breadcrumb-navigation {
            .breadcrumb-list {
                margin: 0;
                padding: 0;

                .breadcrumb-list-item {
                    display: inline-block;
                    list-style-type: none;

                    &:not(:last-child) {
                        margin-right: 8px;

                        &::after {
                            content: "/";
                            margin-left: 8px;
                        }
                    }
                }
            }
        }
    }
}

.home-search-container {
    margin-bottom: 8px;
    padding: 0;
    display: flex;
    flex-direction: row-reverse;
}
