/* ==========================================================================
 Helper classes
 ========================================================================== */

/*
 * Clearfix: contain floats
 *
 * For modern browsers
 * 1. The space content is one way to avoid an Opera bug when the
 *    `contenteditable` attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that receive the `clearfix` class.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */

.clearfix:before,
.clearfix:after {
    content: " "; /* 1 */
    display: table; /* 2 */
}

.clearfix:after {
    clear: both;
}

/*
 * For IE 6/7 only
 * Include this rule to trigger hasLayout and contain floats.
 */

.clearfix {
    *zoom: 1;
}

/*
 * Include this class to an element whose children you want to vertically align
 */
.center-vertical::before,
.center-vertical-children::before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: 0;
}

.center-vertical > *,
.center-vertical-children > * {
    display: inline-block;
    vertical-align: middle;
}

/*
 * Contrary to center-vertical, include this class to an element you want to vertically align,
 * given the parent element has a specified height.
 */
.center-vertical-self {
    position: relative;
    display: block;
    top: 50%;
    transform: translateY(-50%);
}

/*
 * Borrowing some material CSS for prettier, fuller shadows in elements such as pin buttons, modals, etc
 * https://elements.polymer-project.org/elements/paper-material?active=paper-material&view=demo:demo/index.html
 */
.paper-material {
    transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
}

.paper-material[data-elevation="1"] {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 1px 5px 0 rgba(0, 0, 0, 0.12),
        0 3px 1px -2px rgba(0, 0, 0, 0.2);
}

.paper-material[data-elevation="2"] {
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14),
        0 1px 10px 0 rgba(0, 0, 0, 0.12),
        0 2px 4px -1px rgba(0, 0, 0, 0.4);
}

.paper-material[data-elevation="3"] {
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14),
        0 1px 18px 0 rgba(0, 0, 0, 0.12),
        0 3px 5px -1px rgba(0, 0, 0, 0.4);
}

.paper-material[data-elevation="4"] {
    box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14),
        0 3px 14px 2px rgba(0, 0, 0, 0.12),
        0 5px 5px -3px rgba(0, 0, 0, 0.4);
}

.paper-material[data-elevation="5"] {
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
        0  6px 30px 5px rgba(0, 0, 0, 0.12),
        0  8px 10px -5px rgba(0, 0, 0, 0.4);
}