footer {
    position: relative;
    z-index: 0;
    font-size: 1rem;
    font-weight: 400;
    border-top: 1px solid #e5e5e5;
}

#disclaimer {
    background: #014358;
    color: #FFF;
    position: relative;
}

footer .logo-container,
footer .text-container {
    height : 8rem;
}

footer .logo-container {
    max-width: 300px;
    margin: 0 auto;
}

footer .pull-right {
    clear: both;
    float: none !important;
}

footer .text-container {
    height : 4rem;
    text-align: center;
    font-size: .8rem;
    padding: 0 0.9375rem;
}

/* Extra small devices (phones, less than 768px) */
/* No media query since this is the default in Bootstrap */

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
    footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: 3;
    }

    footer .pull-right {
        clear: none;
        float: right !important;
    }

    footer .text-container {
        height : 8rem;
    }

}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {

}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}
